<template>
  <div class="home industry_home">
    <headert></headert>
    <el-row class="content">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="../../assets/image/banner/landed.png" alt="">
        </div>
      </el-col>
    </el-row>
    <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
    <div class="introduce">
      <div class="padding-tb70">
        <div class="WJgroup-titleBox flex">
          <p class="WJgroup-title">{{$t('property.title')}}</p>
        </div>
      </div>
    </div>
    <el-row class="introduce paddingb150" type="flex" justify="center" >

      <el-col class="leftIno" >

        <div>
          <router-link tag="a" to="/IndustryHouse" class="noactive">
            <span class="font">{{$t('property.href1')}}</span>
          </router-link>
        </div>
        <div>
          <router-link tag="a" to="/IndustryCommercial" class="noactive">
            <span class="font">{{$t('property.href2')}}</span>
          </router-link>
        </div>
        <div>
          <router-link tag="a" to="/IndustryProperty" class="noactive">
            <span class="font">{{$t('property.href3')}}</span>

          </router-link>
        </div>
      </el-col>
      <el-col class="rightIno">
        <el-row class="title">
<!--          <h2 >——维佳地产始终坚持“<b>五品</b>”的筑作理念——</h2>-->
          <h2 v-html="$t('property.idea')"></h2>
          <p>{{$t('property.ideaSub')}}</p>
        </el-row>
        <el-row class="concept" type="flex" justify="center" :style="{'padding':lang=='zh'?'0px 0px':'20px 0'}">
          <div class="flex justify_center">
            <img class="wow animate__animated flipInX" src="../../assets/image/highStandard.png" alt />
            <span>{{$t('property.ideaItem1')}}</span>
          </div>
          <i class="el-icon-plus"></i>
          <div class="flex justify_center">
            <img class="wow animate__animated flipInX" src="../../assets/image/craftsman.png" alt />
            <span>{{$t('property.ideaItem2')}}</span>
          </div>
          <i class="el-icon-plus"></i>
          <div class="flex justify_center">
            <img class="wow animate__animated flipInX" src="../../assets/image/framer.png" alt />
            <span>{{$t('property.ideaItem3')}}</span>
          </div>
          <i class="el-icon-plus"></i>
          <div class="flex justify_center">
            <img class="wow animate__animated flipInX" src="../../assets/image/Master.png" alt />
            <span>{{$t('property.ideaItem4')}}</span>
          </div>
          <i class="el-icon-plus"></i>
          <div class="flex justify_center">
            <img class="wow animate__animated flipInX" src="../../assets/image/market.png" alt />
            <span>{{$t('property.ideaItem5')}}</span>
          </div>
        </el-row>

        <div class="IndustryBox">
<!--          产业介绍-->
          <h3 class="IndustryTitle">{{$t('property.introduction')}}</h3>
<!--          <p class="IndustryFont">{{$t('property.introductionDesc')}}</p>-->
          <p class="IndustryFont" >{{typeInfo.idea}}</p>

          <img v-if="lang=='zh'" style="width: 100%;" src="../../assets/image/Industry/img1.png" alt="">
          <img v-else style="width: 100%;" src="../../assets/image/Industry/img1en.png" alt="">
        </div>
        <div class="IndustryBox">
<!--          战略定位-->
          <h3 class="IndustryTitle">{{$t('property.position')}}</h3>
          <ul class="IndustryPos flex justify_around">
            <li class="IndustryPosdashed">
              <div class="flex flex_column justify_center">
                <img src="../../assets/image/Industry/Ellipse65.png" alt="">
              </div>
              <p>{{$t('property.position1')}}</p>
            </li>
            <li class="IndustryPosdashed">
              <div class="flex flex_column justify_center">
                <img src="../../assets/image/Industry/Ellipse66.png" alt="">
              </div>
              <p>{{$t('property.position2')}}</p>
            </li>
            <li>
              <div class="flex flex_column justify_center">
                <img src="../../assets/image/Industry/Ellipse67.png" alt="">
              </div>
              <p>{{$t('property.position3')}}</p>
            </li>
          </ul>
          <ul class="IndustryCover flex justify_between">
            <li class="flex justify_center flex_column">
<!--                <h6>住宅</h6>-->
<!--                <p>温馨舒适，设施齐全</p>-->
            <h6>{{$t('property.positionTypeTitle1')}}</h6>
            <p>{{$t('property.positionTypedesc1')}}</p>
                        </li>
                        <li class="flex justify_center flex_column">
                          <h6>{{$t('property.positionTypeTitle2')}}</h6>
                          <p>{{$t('property.positionTypedesc2')}}</p>
                        </li>
                        <li class="flex justify_center flex_column">
                          <h6>{{$t('property.positionTypeTitle3')}}</h6>
                          <p>{{$t('property.positionTypedesc3')}}</p>
                        </li>
                        <li class="flex justify_center flex_column">
                          <h6>{{$t('property.positionTypeTitle4')}}</h6>
                          <p>{{$t('property.positionTypedesc4')}}</p>
                        </li>

                        <div class="IndustryCoverName flex justify_center " v-html="$t('property.positionTypeAll')">
<!--                            产业<br>覆盖-->
                        </div>
                      </ul>
                    </div>




                  </el-col>
                </el-row>
                <!-- 底部 -->
    <footerb></footerb>
  </div>
</template>

<script>
  export default {
    name: "Home",
    components: {},
    data() {
      return {
        swiperOptions: {
          autoplay: {
            delay: 1000,
            disableOnInteraction: true
          },

          loop: true,
          effect: "fade",
          fade: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true
            // progressbarOpposite: true,
          }
        },
        menuActiveIndex: '/Industry',
        menu: {
          name: '产业布局',
          path: '/Industry',
          list: [{
            name: this.$t('menu.Property'),
            path: '/IndustryHouse',
          }, {
            name: this.$t('menu.park'),
            path: '/EntertainmentIndustrialPark',
          }, {
            name: this.$t('menu.entertainment'),
            path: '/Entertainment',
          }, {
            name: this.$t('menu.Commerce'),
            path: '/IndustryCommerce',
          }]
        },
        breadcrumb: [{
          name: this.$t('menu.layout'),
          path: '/Industry',
        }, {
          name: this.$t('menu.Property'),
          path: '/Industry',
        }, ],
        typeid:76,
        typeInfo:{}
      };
    },
    computed: {
      lang(){
        return window.localStorage.getItem('language')
      },
    },
    created() {},
    mounted() {
      new this.$wow.WOW().init()
      this.getData()
    },
    methods: {
      getData(){
        let api='?m=api&c=v1.Api&a=index&_ajax=1';
        this.$request.post(api,{
          apiType_1:`ekey=1&typeid=${this.typeid}&addfields=idea,title_en,idea_en`,
        }).then(res=>{
          this.typeInfo = this.$utils.translate(res.apiType[1].data)
        })
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  .swiperIMg {
    width: 100%;
    height: auto;
  }

  .swiper-container {
    width: 100%;
    max-height: 670px;
  }

  .content {
    overflow: hidden;
  }


  .introduce {
    justify-content: center;
    max-width: 1400px;
    margin:0 auto;


    .leftIno {
      width: 362px;
      .noactive {
        display: inline-block;
        width: 100%;
        height: 116px;
        background: linear-gradient(90deg, #ADAFB2 0%, #8F9294 100%);
        // font-family: 'OPPOSans-M';
        font-style: normal;
        font-weight: 400;
        font-size: 29px;
        line-height: 40px;
        margin-bottom: 1px;
        padding-left: 50px;
        color: #FFFFFF;
        display: flex;
        align-items: center;

      }
    }

    .rightIno {
      width: 986px;
      margin-left: 102px;

      .title {
        //margin-top: 78px;
        width: 874px;
        z-index: 200;
        background: #ffffff;
        text-align: center;

        height: 62px;
        line-height: 40px;
        margin-left: 66px;

        h2 {
          width: 853px;
          font-size: 24px;
          line-height: 26px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1f2d38;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .concept {
        display: flex;
        justify-content: center;
        width: 986px;
        min-height: 200px;
        background: #ffffff;
        border: 1px solid #af8e68;
        position: relative;
        top: -3px;
        text-align: center;

        div {
          margin-top: 43px;
          display: flex;
          flex-direction: column;

          img {
            height: 100px;
            width: 100px;
          }

          span {
            line-height: 18px;
            margin-top: 16px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #af8e68;
          }
        }

        i {
          margin-right: 30px;
          margin-left: 30px;
          margin-top: 89px;
          width: 13px;
          height: 13px;
          font-size: 25px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #aaaaaa;
        }
      }

      .shadow {
        margin-left: 13px;
        width: 921px;
        height: 54px;
        opacity: 0.38;
        position: relative;
        top: -27px;
      }

    }
  }




  .IndustryPos {
    margin: 66px 0 70px;
  }

  .IndustryPosdashed {
    position: relative;

  }

  .IndustryPosdashed::before {
    content: "";
    position: absolute;
    top:50%;
    right: -90%;
    width: 80%;
    border-top: 1px dashed #D5B88E;
  }

  .IndustryPos div {
    width: 164px;
    height: 150px;
    background: url('../../assets/image/Industry/img3.png') center;
    background-size: 100% 100%;
  }

  .IndustryPos div img {
    width: 129px;
    height: 129px;
    margin-top: 12px;
  }

  .IndustryPos p {
    text-align: center;
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #333333;
  }
.IndustryCover {
  flex-wrap: wrap;
  position: relative;
}
  .IndustryCover li{
    width: 49.5%;
    height: 147px;
    background: #F2E2CA;
    border-radius: 27px;
    margin-top: 10px;
  }
   .IndustryCover li h6{
      // font-family: 'OPPOSans';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 48px;
      color: #8F6B4B;
  }

   .IndustryCover li p{
      // font-family: 'OPPOSans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;

      color: #8F6B4B;
   }
   .IndustryCoverName{
      width: 209px;
      height: 209px;
      background: #DDB06E;
      border: 10px solid #FFFFFF;
      // font-family: 'OPPOSans-B';
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 46px;
      border-radius: 50%;
      color: #FFFFFF;
      position: absolute;
      top:50%;
      left: 50%;
      margin-top: -103px;
      margin-left: -103px;
   }


  @media screen and (min-width:320px) and (max-width:767px) {
    .industry_home .introduce{
      display: block;
      padding: 0 20px;
      height: auto;
    }
    .industry_home .introduce .leftIno{
      width: 100%!important;
      display: flex;
      float: unset;
      margin-bottom: 40px;
    }
    .introduce .leftIno .noactive{
      justify-content: center;
      padding-left: 0;
      font-size: 39px;
    }
    .industry_home .introduce .leftIno div{
       width: 33%;
      text-align: center;
    }
    .industry_home .introduce .rightIno{
      width: 100%!important;
      margin-left: 0;
      float: unset;
    }
    .introduce .rightIno .title h2{
      font-size: 34px;
    }
    .introduce .rightIno .title p{
      font-size: 28px;
      line-height: 40px;
    }
    .introduce .rightIno .concept{
      padding-top: 50px!important;
      padding-bottom: 20px!important;
    }
    .introduce .rightIno .concept div span{
      line-height: 40px;
      font-size: 30px;
    }
    .introduce .rightIno .concept{
      width: 100%;
    }
    .introduce .IndustryTitle{
      font-size: 39px;
      line-height: 50px;
    }
    .introduce .IndustryFont{
      font-size: 34px;
      line-height: 44px;
    }
    .introduce .IndustryPos p{
      font-size: 34px;
      margin-top: 30px;
    }
    .IndustryBox{
      padding-bottom: 70px;
    }
    .introduce .IndustryCover{
      padding-top: 260px;
      margin-top: 0px;

    }
    .introduce  .IndustryCoverName{
        top:100px;
      border-color: #f2e2ca;
    }
    .introduce .IndustryCover li{
      width: 100%;
      border-radius: 10px;
      h6{
        font-size: 39px;
        line-height: 50px;
      }
      p{
        font-size: 34px;
        line-height: 50px;
        margin-top: 10px;
      }
    }

  }
</style>
